;
(function (ng) {
  'use strict';

  var SpinboxCtrl = /* @ngInject */function ($element, $timeout, spinboxKeyCodeAllow, spinboxTooltipTextType, $translate) {
    var ctrl = this,
      timeoutTooltip = null,
      TOOLTIP_TIMER = 3000,
      callbackTimerId;

    //ctrl.min = ctrl.min() || 0;
    //ctrl.max = ctrl.max() || Number.POSITIVE_INFINITY;
    //ctrl.step = ctrl.step() || 1;

    var callbackCall = function (value) {
      if (callbackTimerId != null) {
        $timeout.cancel(callbackTimerId);
      }
      callbackTimerId = $timeout(function () {
        // && ctrl.form.$valid
        if (ctrl.updateFn != null) {
          ctrl.updateFn({
            value: value,
            proxy: ctrl.proxy
          });
        }
      }, 700);
    };
    ctrl.$onInit = function () {
      ctrl.max = ctrl.getNearValue(ctrl.max);
      ctrl.min = ctrl.getNearValue(ctrl.min);
      ctrl.checkButtons(ctrl.value);
      ctrl.tooltipText = null;
    };
    ctrl.less = function () {
      var newValue = ctrl.value - ctrl.step;
      newValue = ctrl.checkRange(newValue) === true ? newValue : ctrl.min != null ? ctrl.min : newValue;
      ctrl.value = ctrl.numberRound(newValue);
      ctrl.checkButtons(ctrl.value);
      callbackCall(ctrl.value);
    };
    ctrl.more = function () {
      var newValue = ctrl.value + ctrl.step;

      // if(isNaN(newValue)) {
      //     newValue = ctrl.min != null ? ctrl.min : 1;
      // } else {
      newValue = ctrl.checkRange(newValue) === true ? newValue : ctrl.max != null ? ctrl.max : newValue;
      // }

      ctrl.value = ctrl.numberRound(newValue);
      ctrl.checkButtons(ctrl.value);
      callbackCall(ctrl.value);
    };
    ctrl.checkRange = function (newValue) {
      return newValue <= ctrl.max && newValue >= ctrl.min;
    };
    ctrl.checkRegex = function (char) {
      return /\d/g.test(char);
    };
    ctrl.keydown = function (event) {
      var symbol;
      if (event.altKey || event.ctrlKey || event.shiftKey) {
        event.preventDefault();
        return;
      }
      var code = ctrl.prepareNumpad(event.keyCode);
      if (!ctrl.isExistKeyCodeAllow(code)) {
        symbol = Number(String.fromCharCode(code));
        if (isNaN(symbol) === true) {
          event.preventDefault();
        }
      } else {
        switch (code) {
          case 40:
            // down arrow
            ctrl.less();
            event.preventDefault();
            break;
          case 38:
            // up arrow
            ctrl.more();
            event.preventDefault();
            break;
        }
      }
    };
    ctrl.keyup = function (event) {
      var code = ctrl.prepareNumpad(event.keyCode),
        symbol = Number(String.fromCharCode(code));
      // ctrl.value = parseFloat($element[0].querySelector('.spinbox-input').value);
      //update if number 

      // callbackCall(ctrl.value);
      // if (isNaN(symbol) === false) {
      //    
      //    
      //     // if(ctrl.form.$valid){
      //     //     callbackCall(ctrl.value);
      //     // }
      // } else if ([8, 49, 110, 188].indexOf(event.keyCode) !== -1 && ctrl.value > 0) {
      //     //'backspace': 8,
      //     //'delete': 46,
      //     //'decimalPoint': 110,
      //     //'comma': 188,
      //     callbackCall(ctrl.value);
      // }
    };

    ctrl.prepareNumpad = function (keycode) {
      return keycode > 95 && keycode < 106 ? keycode - 48 : keycode;
    };
    ctrl.isExistKeyCodeAllow = function (keycode) {
      var result = false;
      for (var key in spinboxKeyCodeAllow) {
        if (spinboxKeyCodeAllow[key] == keycode) {
          result = true;
          break;
        }
      }
      return result;
    };
    ctrl.checkButtons = function (newValue) {
      ctrl.lessBtnDisabled = newValue === ctrl.min;
      ctrl.moreBtnDisabled = newValue === ctrl.max;
    };
    ctrl.valueFoldStep = function () {
      // if(ctrl.value == null || isNaN(ctrl.value)) return
      const value = parseFloat($element[0].querySelector('.spinbox-input').value);
      if (value > ctrl.max) {
        ctrl.value = ctrl.max;
        ctrl.tooltipText = ctrl.getTooltipText(spinboxTooltipTextType.max);
      } else if (isNaN(value) || value < ctrl.min) {
        ctrl.value = ctrl.min;
        ctrl.tooltipText = ctrl.getTooltipText(spinboxTooltipTextType.min);
      } else {
        ctrl.value = value;
      }
      var whole, newValue;
      if (!ctrl.isShareWhole(ctrl.value, ctrl.step) || ctrl.value === 0) {
        whole = ctrl.value - ctrl.getRemainder(ctrl.value, ctrl.step);
        newValue = whole + ctrl.step;
        newValue = ctrl.checkRange(newValue) === true ? newValue : ctrl.max != null ? ctrl.max : newValue;
        ctrl.value = ctrl.numberRound(newValue);
        ctrl.checkButtons(ctrl.value);
        ctrl.tooltipText = ctrl.getTooltipText(spinboxTooltipTextType.multiplicity);
      }
      if (ctrl.tooltipText != null) {
        if (timeoutTooltip) {
          ctrl.hideTooltip();
          $timeout.cancel(timeoutTooltip);
        }
        ctrl.showTooltip();
        timeoutTooltip = $timeout(() => {
          ctrl.tooltipText = null;
        }, TOOLTIP_TIMER);
      }
      callbackCall(ctrl.value);
    };
    ctrl.getLengthAfterSemicolon = value => {
      var _value$toString$split;
      return ((_value$toString$split = value.toString().split(".")[1]) !== null && _value$toString$split !== void 0 ? _value$toString$split : "").length;
    };
    ctrl.numberRound = function (newValue) {
      //http://0.30000000000000004.com/
      //http://stackoverflow.com/questions/588004/is-floating-point-math-broken/588014#588014
      return parseFloat(newValue.toPrecision(12));
    };
    ctrl.getRemainder = (num1, num2) => {
      return ctrl.numberRound(num1 % num2);
    };
    ctrl.isShareWhole = (num1, num2) => {
      const maxLengthAfterSemicolon = Math.max(ctrl.getLengthAfterSemicolon(num1), ctrl.getLengthAfterSemicolon(num2));
      const multiple = Math.pow(10, maxLengthAfterSemicolon || 1);
      const _num1 = multiple * num1;
      const _num2 = multiple * num2;
      const remainder = ctrl.getRemainder(_num1, _num2);
      if (remainder == 0) return true;
      return false;
    };
    ctrl.getNearestLessMultiple = function (number, multiple) {
      return Math.floor(number / multiple) * multiple;
    };
    ctrl.getNearestMoreMultiple = function (number, multiple) {
      return Math.ceil(number / multiple) * multiple;
    };
    ctrl.getNearValue = function (value) {
      if (value >= ctrl.max) {
        return ctrl.isShareWhole(ctrl.max, ctrl.step) ? ctrl.max : ctrl.numberRound(ctrl.getNearestLessMultiple(ctrl.max, ctrl.step));
      } else if (ctrl.min >= value) {
        return ctrl.isShareWhole(ctrl.min, ctrl.step) ? ctrl.min : ctrl.numberRound(ctrl.getNearestMoreMultiple(ctrl.min, ctrl.step));
      }
      return value;
    };
    ctrl.showTooltip = function () {
      ctrl.isOpenTooltip = true;
    };
    ctrl.hideTooltip = function () {
      ctrl.isOpenTooltip = false;
    };
    ctrl.getTooltipText = function (type) {
      if (type === spinboxTooltipTextType.min) {
        return "".concat($translate.instant('Js.Spinbox.MinTextNote'), " ").concat(ctrl.min);
      }
      if (type === spinboxTooltipTextType.max) {
        return "".concat($translate.instant('Js.Spinbox.MaxTextNote'), " ").concat(ctrl.max);
      }
      if (type === spinboxTooltipTextType.multiplicity) {
        return "".concat($translate.instant('Js.Spinbox.MultiplicityTextNote'), " ").concat(ctrl.step);
      }
    };
  };
  SpinboxCtrl.$inject = ["$element", "$timeout", "spinboxKeyCodeAllow", "spinboxTooltipTextType", "$translate"];
  angular.module('spinbox').controller('SpinboxCtrl', SpinboxCtrl);
})(window.angular);