class carouselExtCtrl {
  /*@ngInject*/
  constructor($attrs, $element, $scope) {
    this.$attrs = $attrs;
    this.$scope = $scope;
    this.el = $element[0];
  }
  $onInit() {
    this.carouselExtRoot.addSlide(this);
  }
}
carouselExtCtrl.$inject = ["$attrs", "$element", "$scope"];
;
export default carouselExtCtrl;