export const carouselExtDefault = {
  carouselExtType: 'slider',
  carouselExtStartAt: 0,
  carouselExtPerView: 1,
  carouselExtFocusAt: '0',
  carouselExtGap: 10,
  carouselExtAutoplay: false,
  carouselExtHoverPause: true,
  carouselExtKeyBoard: true,
  carouselExtBound: false,
  carouselExtSwipeThreshold: 80,
  carouselExtDragThreshold: 120,
  carouselExtPerTouch: false,
  carouselExtTouchRatio: 0.5,
  carouselExtTouchAngle: 45,
  carouselExtAnimationDuration: 400,
  carouselExtRewind: true,
  carouselExtRewindDuration: 800,
  carouselExtAnimationTimingFunc: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
  carouselExtDirection: 'ltr',
  carouselExtPeek: 0,
  carouselExtBreakPoints: {},
  carouselExtClasses: {},
  carouselExtThrottle: 25
};